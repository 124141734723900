var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-navigation',[_c('section',{class:_vm.$style.page},[_c('article',{class:_vm.$style.grid},[_c('div',{class:[
        _vm.$style.gridItem,
        _vm.$style.gridItemLeft,
        _vm.$style.gridItemComputers ]},[_c('div',{class:_vm.$style.gridItemComputersTable},[_c('div',{class:_vm.$style.gridItemComputersTableContent},[_c('div',{class:_vm.$style.gridItemComputersTableContentTable},[_c('dragTreeTable',{attrs:{"isdraggable":false,"data":_vm.treeData}})],1)])])]),_c('div',{class:[_vm.$style.gridItemButtons]},[_c('refresh',{class:_vm.$style.refresh,attrs:{"text":"Пересчитать задачи"},on:{"click":_vm.getTasks}}),_vm._l((Object.entries(_vm.taskType)),function(ref){
      var key = ref[0];
      var value = ref[1];
return _c('button',{key:key,class:_vm.$style.actionButton,on:{"click":function($event){return _vm.addTaskForComputers(key)}}},[_c('img',{class:_vm.$style.inline,attrs:{"src":require('@/assets/images/icons/navigation/' +
                value.svg +
                '.svg')}}),_c('div',{class:[_vm.$style.inline, _vm.$style.buttonText]},[_vm._v(" "+_vm._s(value.text)+" ")])])})],2),_c('div',{class:[_vm.$style.gridItem]},[_c('div',{class:_vm.$style.inline},[_c('label',{class:_vm.$style.label},[_vm._v("Показать за ")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.time),expression:"time",modifiers:{"number":true}}],class:_vm.$style.input,attrs:{"type":"number"},domProps:{"value":(_vm.time)},on:{"input":function($event){if($event.target.composing){ return; }_vm.time=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.timeType),expression:"timeType"}],class:_vm.$style.select,on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.timeType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.timeOptions),function(option){return _c('option',{key:option.id,domProps:{"value":option}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)]),_c('div',{class:_vm.$style.resultTableContainer},[(_vm.filteredTasks.length === 0)?_c('div',{class:_vm.$style.emptyResult},[_vm._v(" Нет данных за выбранный период ")]):_c('table',{class:_vm.$style.table},[_c('thead',{class:_vm.$style.header},[_c('tr',_vm._l((_vm.columns),function(column){
                var _obj;
return _c('th',{key:column.field,class:_vm.$style.cell,style:({ width: column.width }),on:{"click":function($event){return _vm.changeSort(column)}}},[_c('div',{class:_vm.$style.inline},[_vm._v(" "+_vm._s(column.name)+" ")]),_c('img',{class:[
                      _vm.$style.inline,
                      _vm.$style.arrow,
                      ( _obj = {}, _obj[_vm.$style.asc] = column.sort == 2, _obj[_vm.$style.hidden] = column.sort == 0, _obj ) ],attrs:{"src":require("@/assets/images/icons/table/sort-arrow.svg")}})])}),0)]),_c('tbody',_vm._l((_vm.sortedTasks),function(task){return _c('tr',{key:task.id,class:_vm.$style.row,on:{"dblclick":function($event){return _vm.openList(task)}}},_vm._l((_vm.columns),function(column){return _c('td',{key:column.field + task.id,class:_vm.$style.cell},[_vm._v(" "+_vm._s(_vm.getField(task[column.field], column.field))+" ")])}),0)}),0)])])])])]),_c('message-text-modal',{class:_vm.$style.modal,attrs:{"id":"MessageText","computer":_vm.currentComputer},on:{"close":function($event){return _vm.closeModal('MessageText')},"add":_vm.addMessageTask}}),_c('hardware-list',{class:_vm.$style.modal,attrs:{"id":"HardwareList","data":_vm.hardwareList},on:{"close":function($event){return _vm.closeModal('HardwareList')}}}),_c('installed-apps-list',{class:_vm.$style.modal,attrs:{"id":"InstalledApps","data":_vm.installedApps},on:{"close":function($event){return _vm.closeModal('InstalledApps')}}}),_c('running-apps',{class:_vm.$style.modal,attrs:{"id":"RunningApps","data":_vm.runningApps},on:{"close":function($event){return _vm.closeModal('RunningApps')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }