<template>
  <modal
    header-text="Список запущенных программ"
    :hide-footer="true"
    class="apps"
    @close="$emit('close')"
  >
    <scrollable-container :class="$style.scrollableContainer">
      <table :class="$style.table">
        <thead :class="$style.header">
          <tr>
            <th
              v-for="column in columns"
              :key="column.field"
              :style="{ width: column.width }"
              :class="$style.cell"
              @click="changeSort(column)"
            >
              <div :class="$style.inline">
                {{ column.name }}
              </div>
              <img
                src="@/assets/images/icons/table/sort-arrow.svg"
                :class="[
                  $style.inline,
                  $style.arrow,
                  {
                    [$style.asc]: column.sort == 2,
                    [$style.hidden]: column.sort == 0,
                  },
                ]"
              >
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(app, index) in sortedApps"
            :key="index"
            :class="$style.row"
          >
            <td
              v-for="column in columns"
              :key="column.field + index"
              :class="$style.cell"
            >
              {{ getField(app[column.field], column.field) }}
            </td>
          </tr>
        </tbody>
      </table>
    </scrollable-container>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/tasks-modal.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: { Modal, ScrollableContainer },
  props: ['data'],
  data: () => ({
    columns: [
      {
        name: 'Приложение',
        width: '30%',
        field: 'description',
        sort: 1,
      },
      {
        name: 'Заголовок окна',
        width: '30%',
        field: 'caption',
        sort: 0,
      },
      {
        name: 'Путь к программе',
        width: '40%',
        field: 'path',
        sort: 0,
      },
    ],
  }),
  computed: {
    sortedApps() {
      const calc = this.data.slice(0).sort(this.compare);
      return calc;
    },
  },
  methods: {
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;

      a = a[key];
      b = b[key];

      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.columns[index].sort === 2) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 1) {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    getField(value, field) {
      if (field === 'description' && value === '') return 'No description';
      return value;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
  },
};
</script>

<style lang="scss" module>
.scrollableContainer {
  max-height: 500px;
  margin: 0px 15px 15px 15px;
}

.header {
  background: #f5f5f5;
  height: 31px;
  position: sticky;
  top: 0px;
  z-index: 3;
  .cell {
    font-weight: 600;
    cursor: pointer;
  }
}
.table {
  width: 100%;
  overflow: auto;
}
.cell {
  padding: 15px 10px 15px 25px;
  text-align: left;
  font-size: 13px;
}
.row {
  height: 30px;
  &:hover {
    background: #f5f5f5;
    transition: background-color 0.5s linear;
    cursor: pointer;
  }
}
.inline {
  display: inline-flex;
}

.arrow {
  margin: 7px 5px 0px 5px;

  &.asc {
    transform: rotate(180deg);
  }
  &.hidden {
    visibility: hidden;
  }
}
</style>

<style >
.apps .tasks-modal_modal_375gM {
  min-width: 850px !important;
}
</style>
