<template>
  <modal
    header-text="Список оборудования"
    :hide-footer="true"
    class="hardware"
    @close="$emit('close')"
  >
    <scrollable-container :class="$style.scrollableContainer">
      <hardware-category
        v-for="[key, value] in Object.entries(data)"
        :key="key"
        :name="key"
        :list="value"
      />
    </scrollable-container>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/tasks-modal.vue';
import hardwareCategory from '@/views/tasks/hardware-category.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: { Modal, hardwareCategory, ScrollableContainer },
  props: ['data'],
};
</script>

<style lang="scss" module>
.scrollableContainer {
  max-height: 500px;
  margin: 0px 15px 15px 15px;
}
</style>

<style >
.hardware .tasks-modal_modal_375gM {
  min-width: 700px !important;
}
</style>
