<template>
  <modal
    header-text="Отправка сообщения"
    button-text="Ок"
    :class="$style.modal"
    @action="$emit('add', text)"
    @close="$emit('close')"
  >
    <div v-if="computer" :class="$style.inputContainer">
      <label :class="$style.label">
        Введите сообщение для пользователя {{ computer.value.name }}
      </label>
      <input v-model="text" type="text" :class="$style.input">
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import Modal from '@/components/common/modal/tasks-modal.vue';

import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: { Modal },
  props: ['computer'],
  data: () => ({
    text: '',
  }),
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
  width: 60%;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  width: 40%;
  line-height: normal;
}
.alert {
  padding: 5px;
  color: $red;
}
</style>
