<template>
  <layout-navigation>
    <section :class="$style.page">
      <article :class="$style.grid">
        <div :class="[
          $style.gridItem,
          $style.gridItemLeft,
          $style.gridItemComputers,
        ]"
        >
          <div :class="$style.gridItemComputersTable">
            <div :class="$style.gridItemComputersTableContent">
              <div :class="$style.gridItemComputersTableContentTable">
                <dragTreeTable
                  :isdraggable="false"
                  :data="treeData"
                />
              </div>
            </div>
          </div>
        </div>
        <div :class="[$style.gridItemButtons]">
          <refresh
            :class="$style.refresh"
            text="Пересчитать задачи"
            @click="getTasks"
          />
          <button
            v-for="[key, value] in Object.entries(taskType)"
            :key="key"
            :class="$style.actionButton"
            @click="addTaskForComputers(key)"
          >
            <img
              :class="$style.inline"
              :src="
                require('@/assets/images/icons/navigation/' +
                  value.svg +
                  '.svg')
              "
            >
            <div :class="[$style.inline, $style.buttonText]">
              {{ value.text }}
            </div>
          </button>
        </div>
        <div :class="[$style.gridItem]">
          <div :class="$style.inline">
            <label :class="$style.label">Показать за </label>
            <input
              v-model.number="time"
              type="number"
              :class="$style.input"
            >
            <select
              v-model="timeType"
              :class="$style.select"
            >
              <option
                v-for="option in timeOptions"
                :key="option.id"
                :value="option"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <div :class="$style.resultTableContainer">
            <div
              v-if="filteredTasks.length === 0"
              :class="$style.emptyResult"
            >
              Нет данных за выбранный период
            </div>
            <table
              v-else
              :class="$style.table"
            >
              <thead :class="$style.header">
                <tr>
                  <th
                    v-for="column in columns"
                    :key="column.field"
                    :style="{ width: column.width }"
                    :class="$style.cell"
                    @click="changeSort(column)"
                  >
                    <div :class="$style.inline">
                      {{ column.name }}
                    </div>
                    <img
                      src="@/assets/images/icons/table/sort-arrow.svg"
                      :class="[
                        $style.inline,
                        $style.arrow,
                        {
                          [$style.asc]: column.sort == 2,
                          [$style.hidden]: column.sort == 0,
                        },
                      ]"
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="task in sortedTasks"
                  :key="task.id"
                  :class="$style.row"
                  @dblclick="openList(task)"
                >
                  <td
                    v-for="column in columns"
                    :key="column.field + task.id"
                    :class="$style.cell"
                  >
                    {{ getField(task[column.field], column.field) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
    </section>
    <message-text-modal
      id="MessageText"
      :computer="currentComputer"
      :class="$style.modal"
      @close="closeModal('MessageText')"
      @add="addMessageTask"
    />
    <hardware-list
      id="HardwareList"
      :data="hardwareList"
      :class="$style.modal"
      @close="closeModal('HardwareList')"
    />
    <installed-apps-list
      id="InstalledApps"
      :data="installedApps"
      :class="$style.modal"
      @close="closeModal('InstalledApps')"
    />
    <running-apps
      id="RunningApps"
      :data="runningApps"
      :class="$style.modal"
      @close="closeModal('RunningApps')"
    />
  </layout-navigation>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import LayoutNavigation from '@/layouts/navigation.vue';
import { getTasks, addTask, readList } from '@/api/methods/tasks';
import dragTreeTable from '@/components/pages/agents/drag-tree-table/dragTreeTable.vue';
import { getComputers } from '@/api/methods/computers';
import Refresh from '@/components/common/filter/refresh.vue';
import MessageTextModal from '@/views/tasks/message-text.vue';
import HardwareList from '@/views/tasks/hardware-list.vue';
import InstalledAppsList from '@/views/tasks/installed-apps-list.vue';
import RunningApps from '@/views/tasks/running-apps.vue';

Vue.use(VueToast);

export default {
  components: {
    LayoutNavigation,
    dragTreeTable,
    Refresh,
    MessageTextModal,
    HardwareList,
    InstalledAppsList,
    RunningApps,
  },
  data: () => ({
    runningApps: [],
    installedApps: [],
    hardwareList: {},
    messages: [],
    currentComputer: null,
    computers: [],
    taskType: {
      7: {
        text: 'Запустить агента',
        svg: 'start',
      },
      8: {
        text: 'Остановить агента',
        svg: 'stop',
      },
      9: {
        text: 'Обновить логи',
        svg: 'refresh2',
      },
      10: {
        text: 'Сделать скриншот',
        svg: 'screenshot',
      },
      11: {
        text: 'Отправить сообщение',
        svg: 'message',
      },
      13: {
        text: 'Обновить список',
        svg: 'people',
      },
      21: {
        text: 'Запущенные программы',
        svg: 'apps',
      },
      22: {
        text: 'Установленные программы',
        svg: 'data',
      },
      27: {
        text: 'Список оборудования',
        svg: 'hardware',
      },
    },
    taskStage: {
      0: 'Поставлено в очередь',
      1: 'На выполнении',
      2: 'Уже есть в очереди',
      3: 'Выполнено',
      4: 'Ошибка выполнения',
    },
    checkedComputers: [],
    tasksTabledata: [],
    time: 5,
    timeType: { id: 0, name: 'мин', seconds: 60 },
    timeOptions: [
      { id: 0, name: 'мин', seconds: 60 },
      { id: 1, name: 'час', seconds: 60 * 60 },
      { id: 2, name: 'дн', seconds: 60 * 60 * 24 },
    ],
    columns: [
      {
        name: 'Задание',
        width: '25%',
        field: 'taskType',
        sort: 0,
      },
      {
        name: 'Время',
        width: '25%',
        field: 'taskTime',
        sort: 1,
      },
      {
        name: 'Имя компьютера',
        width: '25%',
        field: 'compName',
        sort: 0,
      },
      {
        name: 'Статус',
        width: '25%',
        field: 'stage',
        sort: 0,
      },
    ],
    computersNumber: 0,
    treeData: {
      columns: [
        {
          type: 'checkbox',
          isContainChildren: true,
          title: '',
          field: 'checked',
          maxWidth: '5%',
          padding: '5px',
          align: 'left',
          flex: 3,
        },
        {
          type: 'selection',
          title: 'Пользователь',
          field: 'title',
          align: 'left',
          flex: 3,
        },
        {
          title: 'Имя компьютера',
          field: 'user',
          align: 'left',
          flex: 2,
        },
      ],
      lists: [],
      custom_field: {
        id: 'ident',
        lists: 'children',
        parent_id: 'parentId',
      },
    },
  }),
  computed: {
    sortedTasks() {
      const calc = this.filteredTasks.slice(0).sort(this.compare);
      return calc;
    },
    filteredTasks() {
      if (this.tasksTabledata.length > 0) {
        const startTime = new Date().getTime() - this.time * this.timeType.seconds * 1000;
        return this.tasksTabledata.filter(
          (item) => new Date(item.taskTime).getTime() > startTime,
        );
      }
      return [];
    },
  },
  async created() {
    this.pollData();
    await this.fetch();
  },
  methods: {
    async openList(task) {
      if (
        (task.taskType === 21
          || task.taskType === 22
          || task.taskType === 27)
        && task.stage === 3
      ) {
        let type = 3;
        if (task.taskType === 21) type = 1;
        if (task.taskType === 22) type = 2;
        const list = await readList(task.compId, type);
        if (type === 3) {
          this.hardwareList = list;
          this.openModal('HardwareList');
        } else if (type === 2) {
          this.installedApps = list;
          this.openModal('InstalledApps');
        } else if (type === 1) {
          this.runningApps = list;
          this.openModal('RunningApps');
        }
      }
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;

      a = a[key];
      b = b[key];

      if (typeof a === 'string' && typeof b === 'string') {
        a = a.toLowerCase();
        b = b.toLowerCase();
      }

      if (this.columns[index].sort === 1) {
        if (a > b) {
          return -1;
        }

        if (a < b) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a < b) {
          return -1;
        }

        if (a > b) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    getCheckedComputers(checked) {
      checked.forEach((comp) => {
        if (comp.type === 'computer' && comp.checked) {
          this.checkedComputers.push({ id: comp.id, name: comp.nameUser });
        } else if (comp.children && comp.children.length > 0) {
          this.getCheckedComputers(comp.children);
        }
      });
    },
    async addMessageTask(text) {
      this.messages.push(text);
      if (this.checkedComputers.length === this.messages.length) {
        await addTask(11, this.checkedComputers, this.messages);
        await this.getTasks();
        this.closeModal('MessageText');
      } else {
        const newIndex = this.currentComputer.index + 1;
        this.currentComputer = {
          index: newIndex,
          value: this.checkedComputers[newIndex],
        };
      }
    },
    async addTaskForComputers(type) {
      this.checkedComputers = [];
      this.getCheckedComputers(this.treeData.lists);
      if (this.checkedComputers.length > 0) {
        if (type !== '11') {
          await addTask(type, this.checkedComputers, []);
          await this.getTasks();
        } else {
          this.currentComputer = {
            index: 0,
            value: this.checkedComputers[0],
          };
          this.openModal('MessageText');
        }
      } else {
        Vue.$toast.open({
          message: 'Нет выбранных пользователей',
          type: 'error',
        });
      }
    },
    getField(value, field) {
      if (field === 'taskType') return this.taskType[value].text;
      if (field === 'stage') return this.taskStage[value];
      return value;
    },
    setNodeAttributes(node) {
      node.ident = node.id + node.name;
      if (node.type === 'folder') {
        node.opened = true;
        node.open = true;
        node.title = node.name;
        node.isContainChildren = true;
        node.children.forEach((child) => this.setNodeAttributes(child));
      } else {
        node.opened = false;
        node.dropDisabled = true;
        node.title = node.nameUser;
        node.user = node.name;
      }
    },
    pollData() {
      this.interval = setInterval(this.getTasks, 2000);
    },
    async getTasks() {
      const result = await getTasks();
      result.forEach((item) => {
        const computer = this.computers.find((comp) => comp.id === item.compId);
        if (computer) item.compName = computer.nameUser;
        else item.compName = '';
      });
      this.tasksTabledata = result;
    },
    async fetch() {
      await getComputers().then(({ tree, computers }) => {
        tree.forEach((item) => this.setNodeAttributes(item));
        this.treeData.lists = tree;
        this.computers = computers;
        this.computersNumber = computers.length;
      });
      await this.getTasks();
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.page {
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.grid {
  border-left: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  display: flex;
  background: white;
  height: 100%;
  overflow: auto;
  display: flex;
}

.gridItem {
  flex: 0 0 60%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.gridItemLeft {
  flex: 0 0 30%;
}

.gridItemButtons {
  flex: 0 0 10%;
  display: flex;
  margin: 20px 0px;
  flex-direction: column;
  padding-right: 20px;
  border-right: 1px solid #e3e3e3;
  min-width: 130px;
}

.gridItemComputers {
  display: flex;
  min-width: 400px;
}

.gridItemComputersTable {
  flex: 1 1 100%;
  height: 100%;
}

.gridItemComputersTableContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid $borderColor;
}

.gridItemComputersTableContentTable {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.label {
  margin: 0px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.input {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 55px;
  margin-right: 5px;
}

.inline {
  display: inline-flex;
}

.arrow {
  margin: 7px 5px 0px 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 70px;
  cursor: pointer;
}

.emptyResult {
  margin: 30px;
  display: flex;
  justify-content: center;
}

.header {
  background: #f5f5f5;
  height: 31px;
  position: sticky;
  top: 0px;
  z-index: 3;

  .cell {
    font-weight: 600;
    cursor: pointer;
  }
}

.table {
  width: 100%;
  overflow: auto;
}

.cell {
  padding: 15px 10px 15px 25px;
  text-align: left;
  font-size: 13px;
}

.row {
  height: 30px;

  &:hover {
    background: #f5f5f5;
    transition: background-color 0.5s linear;
    cursor: pointer;
  }
}

.resultTableContainer {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.actionButton {
  margin: 10px 0px;
  height: 15%;
  background: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  min-width: 110px;

  &:hover {
    background: #f5f5f5;
    transition: background-color 0.5s linear;
    cursor: pointer;
  }
}

.refresh {
  margin: 10px;
}

.modal {
  display: none;
  z-index: 4;
}

.buttonText {
  padding: 0px 7px;
}
</style>

<style>
.drag-tree-table {
  margin: 0;
  color: black;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.drag-tree-table-header {
  background: #f5f5f5;
  line-height: 20px;
}

.refresh_img_1Pd3z {
  min-width: 20px;
}
</style>
