<template>
  <div :class="$style.categoryContainer">
    <div :class="$style.nameContainer" @click="open = !open">
      <div :class="[$style.expandButton, { [$style.expanded]: open }]" />
      <div :class="$style.categoryName">
        {{ name }}
      </div>
    </div>
    <div
      v-for="(item, index) in list"
      v-show="open"
      :key="index"
      :class="$style.itemsContainer"
    >
      <div
        v-for="[key, value] in Object.entries(item)"
        :key="key"
        :class="$style.attribute"
      >
        {{ key }}: {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['name', 'list'],
  data: () => ({
    open: true,
  }),
};
</script>

<style lang="scss" module>
.categoryContainer {
  margin: 10px 0px;
}
.categoryName {
  margin: 10px 0px;
  width: 100%;
}
.attribute {
  margin: 5px 0px;
}
.itemsContainer {
  padding: 5px 20px;
}

.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  margin-top: 2px;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}

.nameContainer {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
}
</style>
