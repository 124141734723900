<template>
  <modal
    header-text="Список установленных программ"
    :hide-footer="true"
    class="installedApps"
    @close="$emit('close')"
  >
    <scrollable-container :class="$style.scrollableContainer">
      <div v-for="(app, index) in data" :key="index" :class="$style.app">
        {{ app }}
      </div>
    </scrollable-container>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/tasks-modal.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: { Modal, ScrollableContainer },
  props: ['data'],
};
</script>

<style lang="scss" module>
.scrollableContainer {
  max-height: 500px;
  margin: 0px 15px 15px 15px;
}

.app {
  min-height: 22px;
}
</style>
